<template>
  <div class="page-wrapper">
    <div class="auth-bg-disabled">
      <div class="authentication-box" style="width: 720px;">

        <div class="text-center m-t-40">
          <router-link to="/" class="logo">
            <img src="../../assets/images/gurumandala-logo.png" alt="">
          </router-link>
        </div>

        <div class="card btn-square mt-4 p-4">
          <h4 class="text-center">STUDENT REGISTRATION</h4>
          <h6 class="text-center">Enter your details to Sign Up</h6>


          <form @submit="submitForm" class="theme-form"  data-parsley-validate="true">

            <template>
              <div class="form-row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>First Name   <span class="font-danger">*</span></label>
                    <b-form-input
                        v-model="form.first_name"
                        :state="hasError('first_name')"
                        class="btn-square"
                        data-parsley-pattern="^[a-zA-Z]+$/"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="hasError('first_name')">{{getError('first_name')}}</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Last Name   <span class="font-danger">*</span></label>
                    <b-form-input v-model="form.last_name" :state="hasError('last_name')" class="btn-square" required></b-form-input>
                    <b-form-invalid-feedback :state="hasError('last_name')">{{getError('last_name')}}</b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>NIC   <span class="font-danger">*</span></label>
                    <b-form-input v-model="form.nic" :state="hasError('nic')" minlength="10" maxlength="12" class="btn-square" required></b-form-input>
                    <b-form-invalid-feedback :state="hasError('nic')">{{getError('nic')}}</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Mobile <span class="font-danger">*</span></label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text pb-0"><span>94</span></div>
                      </div>
                      <b-form-input :disabled="form.nic.length !== 10 && form.nic.length !== 12" v-model="form.phone_mobile" :state="hasError('phone_mobile')" minlength="9" maxlength="9" class="btn-square" required></b-form-input>
                    </div>
                    <sub class="text-muted">Ex: 94 771112233 (last 9 digits)</sub>
                    <b-form-invalid-feedback :state="hasError('phone_mobile')">{{getError('phone_mobile')}}</b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </template>

            <template>
              <div class="form-group">
                <label>Class Years <span class="font-danger">*</span></label>
                <b-form-tags @keydown="fetchClasses" @change="yearChanged" input-id="tags-basic" v-model="form.years" placeholder="Enter class year(s)" required></b-form-tags>
                <b-form-invalid-feedback :state="hasError('year')">{{getError('year')}}</b-form-invalid-feedback>
              </div>
              <div class="form-row" >
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Institute   <span class="font-danger">*</span></label>
                    <b-form-select @change="fetchClasses" class="btn-square border" v-model="form.institute_id" :options="institute_selections" value-field="id" text-field="text" :state="hasError('institute_id')" required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Select an Institute --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="hasError('institute_id')">{{getError('institute_id')}}</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Stream <span class="font-danger">*</span></label>
                    <b-form-select @change="fetchClasses" class="btn-square border" v-model="form.stream_id" :options="stream_selections" value-field="id" value="text" :state="hasError('stream_id')" required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Select a Stream --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="hasError('stream_id')">{{getError('stream_id')}}</b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <template v-if="class_selections.length">
                <div class="animate-chk p-b-20">
                  <label>Classes <span class="font-danger">*</span></label>
                    <div class="checkbox-animated" v-for="(cls, index) in class_selections" :key="index">
                      <label v-if="form.stream_id" class="d-block text-capitalize">
                        <input class="checkbox_animated" type="checkbox" v-model="form.classes" :value="cls.id" :checked="form.classes.includes(cls.id)" > {{cls.text}}
                      </label>
                    </div>
                </div>
              </template>
              <template v-else>
                  <b-form-group label="Classes">
                    <b-form-input :disabled="true" :readonly="true" class="bg-light"></b-form-input>
                  </b-form-group>
              </template>
            </template>

            <template>
              <div class="form-row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Password   <span class="font-danger">*</span></label>
                    <b-form-input type="password" v-model="form.password" :state="hasError('password')" class="btn-square" required></b-form-input>
                    <b-form-invalid-feedback :state="hasError('password')">{{getError('password')}}</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Confirm Password <span class="font-danger">*</span></label>
                    <b-form-input type="password" :disabled="!form.password.length" v-model="form.password_confirmation" :state="hasError('password_confirmation')" class="btn-square" required></b-form-input>
                    <b-form-invalid-feedback :state="hasError('password_confirmation')">{{getError('password_confirmation')}}</b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Username <span class="font-danger">*</span></label>
                    <b-form-input
                        :disabled="!form.password_confirmation.length"
                        v-model="form.username"
                        :state="hasError('username')"
                        class="btn-square"
                        data-parsley-pattern="/^[a-zA-Z]+$/u"
                        data-parsley-trigger="keyup"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="hasError('username')">{{getError('username')}}</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Mobile Verification (OTP) <span class="font-danger">*</span></label>
                    <b-form-input v-model="form.otp" :state="hasError('otp')" class="btn-square" required></b-form-input>
                    <b-form-invalid-feedback :state="hasError('otp')">{{getError('otp')}}</b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </template>

            <div class="row m-t-20">
              <div class="col-12 col-md-6">
<!--                <b-button class="btn btn-square btn-block border-1" variant="light" @click=""><strong>Login</strong></b-button>-->
<!--                <a href="http://classroom.gurumandala.lk/login" class="btn btn-square btn-block border-1"><strong>Login</strong></a>-->
              </div>
              <div class="col-12 col-md-6" v-if="!form.otp.length">
                <b-button :disabled="!allowOtpGeneration" class="btn btn-square btn-block" variant="success" @click="sendMobileVerification"><strong>Get Mobile Code</strong></b-button>
              </div>
              <div class="col-12 col-md-6" v-else>
                <b-button :disabled="!form.otp_hash.length" class="btn btn-square btn-block" variant="success" @click="submitForm"><strong>Submit</strong></b-button>
              </div>
            </div>

          </form>

        </div>


      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
export default {
  name: "register",
  created() {
    API.select('institutes').then((response) => {
      this.institute_selections = response.data.data
      this.institute_selections.splice(this.institute_selections.indexOf('migration'),1)
    }).catch((errors) => {
      console.log(errors)
      this.institute_selections = []
    })
    API.select('streams').then((response) => {
      this.stream_selections = response.data.data
    }).catch((errors) => {
      console.log(errors)
      this.stream_selections = []
    })
  },
  data() {
    return {
      errorBag: null,
      institute_selections: [],
      stream_selections: [],
      class_selections: [],
      last_otp_at: 0,
      step: 0,
      form: {
        nic: '',
        first_name: '',
        last_name: '',
        phone_mobile: '',
        phone_home: '',
        username: '',
        password: '',
        password_confirmation: '',
        years: [new Date().getFullYear()],
        institute_id: null,
        stream_id: null,
        dob: '',
        gender: 0,
        classes: [],
        otp_hash: '',
        otp: ''
      }
    }
  },
  methods: {
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    fetchClasses() {
      API.select('classes', {
        institute_id: this.form.institute_id,
        stream_id: this.form.stream_id,
        years: this.form.years
      }).then((response) => {
        this.class_selections = response.data.data
      }).catch((errors) => {
        console.log(errors)
        this.class_selections = []
      })
    },
    yearChanged(){ this.fetchClasses() },
    sendMobileVerification(){
      if((300+this.last_otp_at) < new Date().getTime()) {
        this.nicChanged();

        API.post('/api/send-signup-otp', {
          token: this.form.nic,
          phone_mobile: this.form.phone_mobile
        }).then((response) => {
          if (response.data.success === true) {
            this.errorBag = null
            this.last_otp_at = 0
            this.form.otp_hash = response.data.data.hash
            this.form.otp = ''
            this.$toasted.success(response.data.message)
          } else {
            this.errorBag = response.data.data
            this.$toasted.error(response.data.message)
          }
        }).catch((errors) => {
          console.log('ERRORS', errors)
        })
      } else {
        this.$toasted.info('Please try again in 5 minutes.')
      }

      this.last_otp_at = new Date().getTime();
    },
    allowOtpGeneration() {
      return (300+this.last_otp_at) < new Date().getTime()
    },
    submitForm() {
      if(this.allowOtpGeneration()) {
        this.nicChanged();
        API.post('/api/register', this.form)
            .then((response) => {
              if (response.data.success === true) {
                this.errorBag = null
                //this.resetForm()
                this.$toasted.success(response.data.message)
                this.$router.push({ name: 'registered' })
              } else {
                this.errorBag = response.data.data
                this.$toasted.error(response.data.message)
              }
            }).catch((errors) => {
              console.log('ERRORS', errors)
            })
      } else {
        this.$toasted.info('Please try again in 5 minutes.')
      }

      this.last_otp_at = new Date().getTime();
    },



    // BEGIN NIC CHANGE FUNCTION
    nicChanged() {
      let dayText = 0;
      let year = "";
      let month = "";
      let day = "";
      if (this.form.nic.length !== 10 && this.form.nic.length !== 12) {
        this.$toasted.show('Invalid NIC NO', {
          fullWidth: true,
          fitToScreen: true,
          position: 'top-center',
          type: 'error',
        })
      }
      //else if (this.form.nic.length == 10 && !$.isNumeric(this.form.nic.substr(0, 9))) {
          //$("#error").html("Invalid NIC NO");
      //}
      else {
        // Year
        if (this.form.nic.length === 10) {
          year = "19" + this.form.nic.substr(0, 2);
          dayText = parseInt(this.form.nic.substr(2, 3));
        } else {
          year = this.form.nic.substr(0, 4);
          dayText = parseInt(this.form.nic.substr(4, 3));
        }
        // Gender
        if (dayText > 500) {
          this.form.gender = 1;
          dayText = dayText - 500;
        } else {
          this.form.gender = 0;
        }
        // Day Digit Validation
        if (dayText < 1 && dayText > 366) {
          // Invalid NIC NO
          this.form.dob = null
          this.$toasted.show('Invalid NIC NO', {
            fullWidth: true,
            fitToScreen: true,
            position: 'top-center',
            type: 'error',
          })
        } else {
          //Month
          if (dayText > 335) { day = dayText - 335; month = "12"; }
          else if (dayText > 305) { day = dayText - 305; month = "11"; }
          else if (dayText > 274) {
            day = dayText - 274;
            month = "10";
          }
          else if (dayText > 244) {
            day = dayText - 244;
            month = "09";
          }
          else if (dayText > 213) {
            day = dayText - 213;
            month = "08";
          }
          else if (dayText > 182) {
            day = dayText - 182;
            month = "07";
          }
          else if (dayText > 152) {
            day = dayText - 152;
            month = "06";
          }
          else if (dayText > 121) {
            day = dayText - 121;
            month = "05";
          }
          else if (dayText > 91) {
            day = dayText - 91;
            month = "04";
          }
          else if (dayText > 60) {
            day = dayText - 60;
            month = "03";
          }
          else if (dayText < 32) {
            month = "01";
            day = dayText;
          }
          else if (dayText > 31) {
            day = dayText - 31;
            month = "02";
          }
          this.form.dob = year + '/' + month + '/' + day
        }
      }
    }
    // END NIC CHANGE FUNCTION
  }
}
</script>

<style scoped>

</style>
